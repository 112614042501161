import { talentClient } from "@/api";
import chunk from "lodash/chunk";
// import { parseTalentUser } from "@/api/utils/dataParsers";
const PREFFRED_PAGE_SIZE = 30;
export default {
  namespaced: true,
  state: {
    users: {},
    artefactTypes: null,
    allRoutes: [],
    achievementsRoles: {},
    brands: [],
    /**
     * Компетенции конкурса Талант
     */
    competences: {},
    competencesGroups: {},
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = { ...state.users, ...payload };
    },
    UPDATE_USER(state, user) {
      state.users[user.id] = { ...state.users[user.id], ...user };
    },
    SET_ARTEFACT_TYPES(state, data) {
      state.artefactTypes = data;
    },
    SET_ROUTES(state, routes) {
      state.allRoutes = routes;
    },
    SET_COMPETENCES(state, payload) {
      state.competences = payload;
    },
    SET_COMPETENCES_GROUPS(state, payload) {
      state.competencesGroups = payload;
    },
    SET_ACHIEVEMENTS_ROLES(state, payload) {
      state.achievementsRoles = payload;
    },
    SET_BRANDS(state, payload) {
      state.brands = payload;
    },
  },
  actions: {
    /**
     * Получает информацию о пользователе из Таланта
     * или из стора, если уже спрашивали
     * @param {number[]} ids
     * @returns
     */
    async getUsers({ state, commit }, ids = []) {
      // берем только уникальные значения, которых нет в сторе
      const newUsers = [...new Set(ids)].filter((id) => {
        return !state.users[id];
      });
      if (!newUsers.length) return;
      /**
       * Разбиваем список id на чанки
       */
      const requests = chunk(newUsers, PREFFRED_PAGE_SIZE).map((list) => {
        return talentClient({
          method: "GET",
          url: `/users/`,
          params: {
            ids: list.join(","),
            limit: PREFFRED_PAGE_SIZE,
            offset: 0,
          },
        });
      });
      /** @TODO может тут лучше allSettled? */
      const data = await Promise.all(requests);
      /**
       * Собираем из результатов каждого запроса
       * коллекцию ключ-значение где ключ - id значение - TalentUser
       */
      const users = data.reduce((acc, request) => {
        if (Array.isArray(request.data?.results)) {
          acc = {
            ...acc,
            ...request.data.results.reduce((collection, user) => {
              collection[user.id] = user;
              return collection;
            }, {}),
          };
        }
        return acc;
      }, {});
      commit("SET_USERS", users);
    },
    async getUserById({ state, commit }, id) {
      if (state.users[id]) return;
      const { data } = await talentClient({
        method: "GET",
        url: `/users/${id}/`,
      });
      commit("SET_USERS", { [data.id]: data });
    },
    async getArtefactTypes({ commit, state }) {
      if (state.artefactTypes) return;
      const { data } = await talentClient({
        method: "GET",
        url: "/project-artefact-types/",
        params: {
          limit: 100,
        },
      });
      commit("SET_ARTEFACT_TYPES", data.results);
    },
    /**
     * Получение списка всех направлений
     */
    async getAllRoutes({ state, commit }) {
      if (state.allRoutes?.length) return state.allRoutes;
      const limit = 80;
      const config = {
        method: "GET",
        url: "/routes/",
        params: {
          limit,
          offset: 0,
        },
      };
      const { data } = await talentClient(config);
      let results = data.results || [];
      // если есть еще страницы
      if (data.count > limit) {
        // количество страниц
        const pages = Math.ceil(data.count / limit);
        const requests = [];
        for (let page = 1; page < pages; page++) {
          requests.push(
            talentClient({
              ...config,
              params: {
                ...config.params,
                offset: limit * page,
              },
            })
          );
        }
        const rData = await Promise.all(requests);
        results = rData.reduce((acc, value) => {
          return acc.concat(value.data.results);
        }, results);
      }

      commit("SET_ROUTES", results);
    },
    async getCompetences({ state, commit }) {
      if (Object.keys(state.competences).length) return state.competences;
      const { data } = await talentClient({
        baseURL: "/calcon",
        method: "GET",
        url: "/competences",
        params: {
          limit: 90,
          offset: 0,
        },
      });

      const list = data.results.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {});
      commit("SET_COMPETENCES", list);
      return state.competences;
    },
    async getCompetencesGroups({ state, commit }) {
      if (Object.keys(state.competencesGroups).length) {
        return state.competencesGroups;
      }
      const { data } = await talentClient({
        baseURL: "/calcon",
        method: "GET",
        url: "/competences/groups",
        params: {
          limit: 50,
          offset: 0,
        },
      });

      const list = data.results.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {});
      commit("SET_COMPETENCES_GROUPS", list);
      return state.competencesGroups;
    },
    async getAchievementsRoles({ commit, state }) {
      if (Object.keys(state.achievementsRoles).length) {
        return state.achievementsRoles;
      }
      const { data } = await talentClient({
        method: "GET",
        url: "/achievements/roles/",
      });
      if (data.results.length) {
        const roles = data.results.reduce((acc, value) => {
          acc[value.id] = value;
          return acc;
        }, {});
        commit("SET_ACHIEVEMENTS_ROLES", roles);
        return roles;
      }
    },
    async getBrands({ state, commit }) {
      if (Object.keys(state.brands).length) {
        return state.brands;
      }
      const { data } = await talentClient({
        method: "GET",
        url: "/brands/",
        params: { limit: 100 },
      });
      if (data) {
        commit("SET_BRANDS", data.results);
        return state.brands;
      }
    },
  },
};
