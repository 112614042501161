import Vue from "vue";
import Vuex from "vuex";
import ui from "./ui";
import user from "./user";
import talent from "./talent";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    timeStamp: Date.now(),
    talentUrl: process.env.VUE_APP_TALENT_HOST,
  },
  mutations: {
    SET_TIME_STAMP(state) {
      state.timeStamp = Date.now();
    },
  },
  actions: {},
  modules: {
    ui,
    user,
    talent,
  },
});
