import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import Page404 from "../views/Page404.vue";
import Page403 from "../views/Page403.vue";
import Login from "../views/Login.vue";
import Auth from "../views/Auth.vue";
import { authMiddleware, roleMiddleware } from "./middlewares";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    name: "boards",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/boards/Index.vue"),
  },
  {
    path: "/boards/new",
    name: "boards_create",
    component: () =>
      import(/* webpackChunkName: "boards" */ "../views/boards/Create.vue"),
  },
  {
    path: "/boards/:id",
    name: "board",
    component: () =>
      import(/* webpackChunkName: "boards" */ "../views/boards/_id.vue"),
  },

  {
    path: "/boards/:id/edit",
    name: "boards_update",
    component: () =>
      import(/* webpackChunkName: "boards" */ "../views/boards/Update.vue"),
  },
  {
    path: "/tags",
    name: "tags",
    component: () => import(/* webpackChunkName: "tags" */ "../views/Tags.vue"),
  },
  {
    path: "/tags/import",
    name: "tags_import",
    meta: {
      roles: ["superuser"],
    },
    component: () =>
      import(/* webpackChunkName: "tags" */ "../views/TagsImport.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      roles: ["superuser"],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Search.vue"),
  },
  {
    path: "/contacts/:id",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      authless: true,
    },
  },
  {
    path: "/auth/complete",
    name: "auth",
    component: Auth,
    meta: {
      authless: true,
    },
  },
  {
    path: "/403",
    name: "403",
    component: Page403,
    meta: {
      authless: true,
    },
  },
  {
    path: "*",
    name: "404",
    component: Page404,
    meta: {
      authless: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach(authMiddleware);
router.beforeEach(roleMiddleware);

export default router;
